import store from "@/store";
import AuthService from "@/core/services/AuthService";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

export const routeNames = {
  landingLayout: "evaluation-layout",
  welcomeLanding: "evaluation-welcome",
  loginLanding: "evaluation-login",
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/employment-register",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/employment-register",
        name: "employment-register",
        component: () =>
          import("@/views/apps/employment/EmploymentRegister.vue"),
      },
      {
        path: "/employee-detail/:id",
        name: "employee-detail",
        component: () => import("@/views/apps/employee/EmployeeDetail.vue"),
      },
      {
        path: "/employee-list",
        name: "employee-list",
        component: () => import("@/views/apps/employee/EmployeeList.vue"),
      },
      {
        path: "/partner-list",
        name: "partner-list",
        component: () =>
          import("@/views/apps/settings/partner/PartnerList.vue"),
      },
      {
        path: "/partner-register",
        name: "partner-register",
        component: () =>
          import("@/views/apps/settings/partner/PartnerRegister.vue"),
      },
      {
        path: "/parameter-register",
        name: "parameter-register",
        component: () =>
          import("@/views/apps/settings/parameter/ParameterRegister.vue"),
      },
      {
        path: "/partner-detail/:id",
        name: "partner-detail",
        component: () =>
          import("@/views/apps/settings/partner/PartnerDetail.vue"),
      },
      {
        path: "/user-list",
        name: "user-list",
        component: () => import("@/views/apps/settings/user/UserList.vue"),
      },
      {
        path: "/user-detail/:id",
        name: "user-detail",
        component: () => import("@/views/apps/settings/user/UserDetail.vue"),
      },
      {
        path: "/user-register",
        name: "user-register",
        component: () => import("@/views/apps/settings/user/UserRegister.vue"),
      },
      {
        path: "/upload-file",
        name: "upload-file",
        component: () => import("@/views/apps/settings/upload/UploadFile.vue"),
      },
      {
        path: "/contact-list",
        name: "contact-list",
        component: () =>
          import("@/views/apps/settings/contact/ContactList.vue"),
      },
      {
        path: "/contact-register",
        name: "contact-register",
        component: () =>
          import("@/views/apps/settings/contact/ContactRegister.vue"),
      },
      {
        path: "/contact-detail/:id",
        name: "contact-detail",
        component: () =>
          import("@/views/apps/settings/contact/ContactDetail.vue"),
      },
      {
        path: "/contact-list-preview/:id",
        name: "contact-list-preview",
        component: () =>
          import("@/views/apps/settings/contact/ContactListDetailPreview.vue"),
      },
      {
        path: "/exam-list",
        name: "exam-list",
        component: () => import("@/views/apps/training/exam/ExamList.vue"),
      },
      {
        path: "/exam-register",
        name: "exam-register",
        component: () => import("@/views/apps/training/exam/ExamRegister.vue"),
      },
      {
        path: "/exam-detail/:id",
        name: "exam-detail",
        component: () => import("@/views/apps/training/exam/ExamDetail.vue"),
      },
      {
        path: "/exam-schedule-list",
        name: "exam-schedule-list",
        component: () =>
          import("@/views/apps/training/schedule/ExamScheduleList.vue"),
      },
      {
        path: "/exam-schedule-register",
        name: "exam-schedule-register",
        component: () =>
          import("@/views/apps/training/schedule/ExamScheduleRegister.vue"),
      },
      {
        path: "/exam-schedule-detail/:id",
        name: "exam-schedule-detail",
        component: () =>
          import("@/views/apps/training/schedule/ExamScheduleDetail.vue"),
      },
      {
        path: "/evaluation-list",
        name: "evaluation-list",
        component: () =>
          import("@/views/apps/training/evaluation/EvaluationList.vue"),
      },
      {
        path: "/evaluation-detail/:id",
        name: "evaluation-detail",
        component: () =>
          import("@/views/apps/training/evaluation/EvaluationDetail.vue"),
      },
    ],
  },
  {
    path: "/evaluation-layout",
    name: "evaluation-layout",
    component: () => import("@/views/apps/landing/LandingPage.vue"),
    redirect: "/evaluation-welcome/:id",
    children: [
      {
        path: "/evaluation-welcome/:id",
        name: "evaluation-welcome",
        component: () =>
          import("@/views/apps/landing/pages/WelcomeLanding.vue"),
      },
      {
        path: "/evaluation-login/:id",
        name: "evaluation-login",
        component: () => import("@/views/apps/landing/pages/LoginLanding.vue"),
      },
      {
        path: "/evaluation-question/:id",
        name: "evaluation-question",
        component: () =>
          import("@/views/apps/landing/pages/EvaluationQuestion.vue"),
      },
      {
        path: "/evaluation-result/:id",
        name: "evaluation-result",
        component: () =>
          import("@/views/apps/landing/pages/EvaluationResult.vue"),
      },
    ],
  },
  {
    path: "/recovery-password",
    name: "recovery-password",
    component: () => import("@/views/apps/authentication/RecoveryPassword.vue"),
  },
  {
    path: "/change-password",
    name: "change-password",
    component: () => import("@/views/apps/authentication/ChangePassword.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/",
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/apps/authentication/Login.vue"),
      },
    ],
    props: true,
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/utils/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  // Activar el loader

  store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
  await store.dispatch(Actions.VERIFY_AUTH, {
    token: `Bearer ${AuthService.getToken()}`,
  });
  store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
  //getter for the auth state
  const isAuthenticated = store.getters.isUserAuthenticated;

  const publicRoutes = [
    "login",
    "recovery-password",
    "change-password",
    "evaluation-layout",
    "evaluation-welcome",
    "evaluation-login",
    "evaluation-question",
    "evaluation-result",
    "404",
  ];
  if (
    typeof to.name === "string" &&
    !publicRoutes.includes(to.name) &&
    !isAuthenticated
  ) {
    return { name: "login" };
  }

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  setTimeout(() => {
    // Move the viewer to coordinates 0
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
