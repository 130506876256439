import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { ERROR, TAG_MODULE_EXAM } from "@/core/helpers/constants";
import { IExam, IFilterExam, IParams } from "@/core/interfaces/exam.interface";

export interface StoreInfo {
  exam: IExam;
  listExams: Array<IExam>;
  totalExams: number;
  filterExam: IFilterExam;
}

@Module
export default class ExamModule extends VuexModule implements StoreInfo {
  exam = {} as IExam;
  listExams = [] as Array<IExam>;
  totalExams = 0;
  filterExam = {} as IFilterExam;
  listLabels = [] as Array<string>;

  /**
   * Get exam
   * @returns IExam
   */
  get getExam(): IExam {
    return this.exam;
  }

  /**
   * Get current list exams
   * @returns Array<IExam>
   */
  get getListExams(): Array<IExam> {
    return this.listExams;
  }

  /**
   * Get current total exams
   * @returns number
   */
  get getTotalExams(): number {
    return this.totalExams;
  }

  /**
   * Get current filter contactLists
   * @returns IFilterContactList
   */
  get getFilterExam(): IFilterExam {
    return this.filterExam;
  }

  /**
   * Get current string list
   * @returns string
   */
  get getListLabels(): Array<string> {
    return this.listLabels;
  }

  @Mutation
  [Mutations.SET_EXAM](data: IExam) {
    this.exam = data;
  }

  @Mutation
  [Mutations.SET_FILTER_EXAM](data: IFilterExam) {
    this.filterExam = data;
  }

  @Mutation
  [Mutations.SET_LIST_EXAM](data: Array<IExam>) {
    this.listExams = data;
  }

  @Mutation
  [Mutations.SET_TOTAL_EXAM](data: number) {
    this.totalExams = data;
  }

  @Mutation
  [Mutations.SET_LIST_LABEL](data: Array<string>) {
    this.listLabels = data;
  }

  @Action
  [Actions.LIST_EXAM](params: object) {
    ApiService.setHeader();
    return ApiService.query("/v1/exam", {
      params: params,
    })
      .then(({ data }) => {
        console.log(`${TAG_MODULE_EXAM} | LIST_EXAM | success | `, data);
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_LIST_EXAM, data.content);
        this.context.commit(Mutations.SET_TOTAL_EXAM, data.totalElements);
      })
      .catch(({ response }) => {
        console.log(`${TAG_MODULE_EXAM} | LIST_EXAM | error | `, response);
        this.context.commit(Mutations.SET_LIST_EXAM, []);
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.LIST_EXAM_RETURN](params: object) {
    ApiService.setHeader();
    return ApiService.query("/v1/exam", {
      params: params,
    })
      .then(({ data }) => {
        console.log(`${TAG_MODULE_EXAM} | LIST_EXAM_RETURN | success | `, data);
        return data.content;
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EXAM} | LIST_EXAM_RETURN | error | `,
          response
        );
        return response.data;
      });
  }

  @Action
  [Actions.DETAIL_EXAM](id: number) {
    ApiService.setHeader();
    return ApiService.get(`v1/exam/${id}`)
      .then(({ data }) => {
        console.log(`${TAG_MODULE_EXAM} | DETAIL_EXAM | success | `, data);
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_EXAM, data);
      })
      .catch(({ response }) => {
        console.log(`${TAG_MODULE_EXAM} | DETAIL_EXAM | error | `, response);
        this.context.commit(Mutations.SET_EXAM, {});
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.CREATE_EXAM](payload: object) {
    ApiService.setHeader();
    return ApiService.post("v1/exam", payload)
      .then(({ data }) => {
        console.log(`${TAG_MODULE_EXAM} | CREATE_EXAM | success | `, data);
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_API_RESPONSE, data);
      })
      .catch(({ response }) => {
        console.log(`${TAG_MODULE_EXAM} | CREATE_EXAM | error | `, response);
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
        this.context.commit(Mutations.SET_API_RESPONSE, response.data);
      });
  }

  @Action
  [Actions.UPDATE_EXAM](payload: IParams) {
    ApiService.setHeader();
    return ApiService.patch(`v1/exam/${payload.id}`, payload.payload)
      .then(({ data }) => {
        console.log(`${TAG_MODULE_EXAM} | UPDATE_EXAM | success | `, data);
        this.context.commit(Mutations.PURGE_ERROR);
      })
      .catch(({ response }) => {
        console.log(`${TAG_MODULE_EXAM} | UPDATE_EXAM | error | `, response);
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.DELETE_EXAM](id: number) {
    ApiService.setHeader();
    return ApiService.delete(`v1/exam/${id}`)
      .then(({ data }) => {
        console.log(`${TAG_MODULE_EXAM} | DELETE_EXAM | success | `, data);
        this.context.commit(Mutations.PURGE_ERROR);
      })
      .catch(({ response }) => {
        console.log(`${TAG_MODULE_EXAM} | DELETE_EXAM | error | `, response);
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.LIST_LABEL]() {
    ApiService.setHeader();
    return ApiService.get("/v1/exam/label")
      .then(({ data }) => {
        console.log(`${TAG_MODULE_EXAM} | LIST_LABEL | success | `, data);
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_LIST_LABEL, data);
      })
      .catch(({ response }) => {
        console.log(`${TAG_MODULE_EXAM} | LIST_LABEL | error | `, response);
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }
}
