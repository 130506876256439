import { createApp } from 'vue'
import App from './App.vue'


import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import es from 'element-plus/es/locale/lang/es'
import i18n from "@/core/plugins/i18n";

//begin:: imports the primevue
import PrimeVue from 'primevue/config';

import 'primevue/resources/themes/saga-blue/theme.css'; //theme
import 'primevue/resources/primevue.min.css'; //core CSS
import 'primeicons/primeicons.css'; //icons
//end:: imports the primevue

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate"; // this is for validating forms dynamically

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus, {
    locale: es
});

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
app.use(PrimeVue);

app.mount("#app");
