import { IzipayErrors } from "@/core/helpers/error";
import ApiService from "@/core/services/ApiService";
import AuthService from "@/core/services/AuthService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ERROR, TAG_MODULE_AUTH } from "@/core/helpers/constants";
import { IChangePassword } from "@/core/interfaces/password.interface";
import { IUser } from "@/core/interfaces/user.interfaces";
import { IPartner } from "@/core/interfaces/partner.interface";

export interface UserAuthInfo {
  errors: unknown;
  currentUser: IUser;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  currentUser = {} as IUser;
  isAuthenticated = !!AuthService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get getCurrentUser(): IUser {
    return this.currentUser;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification error
   * @returns array
   */
  get getError() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error: string) {
    this.errors = { ...this.errors, error };
  }

  @Mutation
  [Mutations.PURGE_ERROR]() {
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_AUTH](token: string) {
    this.isAuthenticated = true;
    this.errors = {};
    AuthService.saveToken(token);
  }

  @Mutation
  [Mutations.SET_CURRENT_USER](user: IUser) {
    user.partners = user.partners.filter((item: IPartner) => item.status !== "INACTIVE");
    this.currentUser = user as IUser;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.errors = {};
    AuthService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials: object) {
    delete ApiService.vueInstance.axios.defaults.headers.common["Authorization"];
    return ApiService.post("v1/auth/authenticate", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.PURGE_ERROR);
        console.log(`${TAG_MODULE_AUTH} | LOGIN | success | `, data);
        this.context.commit(Mutations.SET_AUTH, data.token);
      })
      .catch(({ response }) => {
        console.log(`${TAG_MODULE_AUTH} | LOGIN | error | `, response);
        this.context.commit(
          Mutations.SET_ERROR,
          IzipayErrors.INVALID_CREDENTIALS
        );
      });
  }

  @Action
  [Actions.CHANGE_PASSWORD](payload: IChangePassword) {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${payload.token}`;
    return ApiService.patch("v1/me/password", {
      password: payload.password,
    } as object)
      .then(({ data }) => {
        this.context.commit(Mutations.PURGE_ERROR);
        console.log(`${TAG_MODULE_AUTH} | CHANGE_PASSWORD | success | `, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_AUTH} | CHANGE_PASSWORD | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.RECOVERY_PASSWORD](payload: object) {
    return ApiService.post("v1/auth/forget", payload)
      .then(({ data }) => {
        this.context.commit(Mutations.PURGE_ERROR);
        console.log(
          `${TAG_MODULE_AUTH} | RECOVERY_PASSWORD | success | `,
          data
        );
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_AUTH} | RECOVERY_PASSWORD | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  async [Actions.VERIFY_AUTH]() {
    if (AuthService.getToken()) {
      ApiService.setHeader();
      //REQUEST TO VERIFY AUTH
      return await ApiService.get("/v1/me")
        .then(({ data }) => {
          console.log(`${TAG_MODULE_AUTH} | VERIFY_AUTH | success | `, data);
          this.context.commit(Mutations.SET_AUTH, AuthService.getToken());
          this.context.commit(Mutations.SET_CURRENT_USER, data);
        })
        .catch(({ response }) => {
          console.log(`${TAG_MODULE_AUTH} | VERIFY_AUTH | error | `, response);
          this.context.commit(Mutations.SET_ERROR, response || ERROR);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.LOGOUT]() {
    console.log(`${TAG_MODULE_AUTH} | LOGOUT`);
    this.context.commit(Mutations.PURGE_AUTH);
  }
}
