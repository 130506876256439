enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  // REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  // ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  // REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  // ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  RECOVERY_PASSWORD = "recoveryPassword",
  DETAIL_PARAMETER = "detailParameter",
  CHANGE_PASSWORD = "changePassword",
  // REGISTER = "register",
  CREATE_EMPLOYMENT = "createEmployment",
  UPDATE_EMPLOYMENT = "updateEmployment",
  UPDATE_PARTNER = "updatePartner",
  LIST_EMPLOYEE = "listEmployee",
  DETAIL_EMPLOYEE = "detailEmployee",
  UPDATE_EMPLOYEE = "updateEmployee",
  LIST_EMPLOYEE_ATTACHMENT = "listEmployeeAttachment",
  LIST_EMPLOYEE_EMPLOYMENT = "listEmployeeEmployment",
  LIST_EMPLOYEE_COMMENT = "listEmployeeComment",
  CREATE_USER = "createUser",
  CREATE_PARTNER = "createPartner",

  LIST_CONTACT_LISTS = "listContactListsAction",
  LIST_CONTACT_LISTS_RETURN = "listContactListsReturnAction",
  LIST_CONTACT_PREVIEW = "listContactPreviewAction",
  CREATE_CONTACT_LISTS = "createContactListsAction",
  UPDATE_CONTACT_LISTS = "updateContactListsAction",
  DETAIL_CONTACT_LIST = "detailContactListAction",
  CREATE_LIST_CONTACT_CUSTOM = "createListContactCustomAction",
  LIST_CONTACT_CUSTOM = "listContactCustomAction",
  DELETE_CONTACT_CUSTOM = "deleteContactCustomAction",

  LIST_EXAM = "listExamAction",
  LIST_EXAM_RETURN = "listExamReturnAction",
  DETAIL_EXAM = "detailExamAction",
  RESULT_EVALUATION = "resultEvaluationAction",
  DETAIL_EVALUATION = "detailEvaluationAction",
  DETAIL_PUBLIC_EVALUATION_RESULT = "detailEvaluationResultAction",
  DETAIL_PUBLIC_EVALUATION = "detailPublicEvaluationAction",
  AUTH_EVALUATION = "authEvaluationAction",
  CREATE_EXAM = "createExamAction",
  UPDATE_EXAM = "updateExamAction",
  DELETE_EXAM = "deleteExamAction",

  LIST_EXAM_SCHEDULE = "listExamScheduleAction",
  CREATE_EXAM_SCHEDULE = "createExamScheduleAction",
  DETAIL_EXAM_SCHEDULE = "detailExamScheduleAction",
  UPDATE_EXAM_SCHEDULE = "updateExamScheduleAction",
  SEND_NOTIFICATION_EXAM_SCHEDULE = "sendNotificationExamScheduleAction",

  LIST_EVALUATION = "listEvaluationAction",

  LIST_USER = "listUser",
  DETAIL_USER = "detailUser",
  UPDATE_USER = "updateUser",
  LIST_ROLE = "listRole",

  LIST_PARTNER = "listPartner",
  LIST_PARTNER_KAM = "listPartnerKamAction",
  DETAIL_PARTNER = "detailPartner",

  CREATE_COMMENT = "createComment",
  UPDATE_PARAMETER = "updateParameter",
  UPDATE_PARAMETER_DELETE = "updateParameterDelete",
  CREATE_PARAMETER = "createParameter",
  LIST_EMPLOYEE_POSITION = "listEmployeePosition",
  LIST_EMPLOYEE_EVALUATION = "listEmployeeEvaluationAction",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  // UPDATE_USER = "updateUser",
  // FORGOT_PASSWORD = "forgotPassword",
  // SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
  UPLOAD_CREATE_EMPLOYMENT = "uploadCreateEmployment",
  UPLOAD_UPDATE_EMPLOYMENT = "uploadUpdateEmployment",

  LIST_EMPLOYMENT_CATEGORY = "listEmploymentCategoryAction",
  LIST_EMPLOYMENT_POSITION = "listEmploymentPositionAction",

  LIST_LABEL = "listLabelAction",
}

enum Mutations {
  // mutation types
  // SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PARTNER = "setPartner",
  SET_CURRENT_USER = "setCurrentUser",
  SET_PARAMETER = "setParameter",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  // SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  PURGE_ERROR = "purgeError",
  SET_API_RESPONSE = "setApiResponse",
  // SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  // SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  // OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  // SET_THEME_MODE_MUTATION = "setThemeModeMutation",
  SET_LIST_EMPLOYEE = "setListEmployee",
  SET_TOTAL_EMPLOYEE = "setTotalEmployee",
  SET_EMPLOYEE = "setEmployee",
  SET_LIST_EMPLOYEE_ATTACHMENT = "setListEmployeeAttachment",
  SET_LIST_EMPLOYEE_EMPLOYMENT = "setListEmployeeEmployment",
  SET_TOTAL_EMPLOYEE_EMPLOYMENT = "setTotalEmployeeEmployment",
  SET_LIST_EMPLOYEE_COMMENT = "setListEmployeeComment",
  SET_FILTER_EMPLOYEE = "setFilterEmployee",
  SET_FILTER_USER = "setFilterUser",
  SET_LIST_EMPLOYEE_POSITION = "setListEmployeePosition",
  SET_LIST_EMPLOYEE_EVALUATION = "setListEmployeeEvaluation",
  SET_FILTER_LIST_EMPLOYEE_EVALUATION = "setFilterListEmployeeEvaluation",
  SET_TOTAL_LIST_EMPLOYEE_EVALUATION = "setTotalListEmployeeEvaluation",

  SET_LIST_CONTACT_LISTS = "setListContactLists",
  SET_FILTER_CONTACT_LISTS = "setFilterContactLists",
  SET_CONTACT_LIST = "setContactList",
  SET_CONTACT_LIST_PREVIEW = "setContactListPreview",
  SET_TOTAL_CONTACT_LISTS = "setTotalContactLists",
  SET_TOTAL_CONTACT_LIST_PREVIEW = "setTotalContactListPreview",
  SET_LIST_CONTACT_CUSTOM = "setListContactCustom",

  SET_EXAM = "setExam",
  SET_AUTH_EVALUATION = "setAuthEvaluation",
  SET_PASSWORD_EVALUATION = "setPasswordEvaluation",
  SET_FILTER_EXAM = "setFilterExam",
  SET_LIST_EXAM = "setListExam",
  SET_TOTAL_EXAM = "setTotalExam",

  SET_EXAM_SCHEDULE = "setExamSchedule",
  SET_LIST_EXAM_SCHEDULE = "setListExamSchedule",
  SET_TOTAL_EXAM_SCHEDULE = "setTotalExamSchedule",
  SET_FILTER_EXAM_SCHEDULE = "setFilterExamSchedule",

  SET_EVALUATION = "setEvaluation",
  SET_EVALUATION_RESULT = "setEvaluationResult",
  SET_FILTER_EVALUATION = "setFilterEvaluation",
  SET_LIST_EVALUATION = "setListEvaluation",
  SET_TOTAL_EVALUATION = "setTotalEvaluation",
  SET_CODE_EVALUATION = "setCodeEvaluation",

  SET_EVALUATION_HISTORY_MODAL = "setEvaluationHistoryModal",

  SET_LIST_USER = "setListUser",
  SET_TOTAL_USER = "setTotalUser",

  SET_LIST_ROLE = "setListRole",

  SET_LIST_PARTNER = "setListPartner",
  SET_TOTAL_PARTNER = "setTotalPartner",
  SET_LIST_PARTNER_KAM = "setListPartnerKam",

  SET_LIST_EMPLOYMENT_CATEGORY = "setListEmploymentCategory",
  SET_LIST_EMPLOYMENT_POSITION = "setListEmploymentPosition",

  SET_LIST_LABEL = "setListLabel",
}

export { Actions, Mutations };
