import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { ERROR, TAG_MODULE_EMPLOYEE } from "@/core/helpers/constants";
import {
  IFilterEmployee,
  IEmployee,
  IEmployeeAttachment,
  IEmployeeComment,
  IEmployeeEmployment,
  IEmployeePosition,
  IEmployeeEvaluation,
  IFilterEmployeeEvaluation,
} from "@/core/interfaces/employee.interface";
import { IParams } from "@/core/interfaces/user.interfaces";
import { IEvaluation } from "@/core/interfaces/evaluation.interface";

export interface StoreInfo {
  employee: IEmployee;
  listEmployeeAttachments: Array<IEmployeeAttachment>;
  listEmployeeComments: Array<IEmployeeComment>;
  listEmployeeEmployments: Array<IEmployeeEmployment>;
  totalEmployeeEmployment: number;
  listEmployees: Array<IEmployee>;
  listEmployeePositions: Array<IEmployeePosition>;
  listEmployeeEvaluations: Array<IEmployeeEvaluation>;
  totalListEmployeeEvaluations: number;
  filterListEmployeeEvaluations: IFilterEmployeeEvaluation;
  totalEmployee: number;
  filterEmployee: IFilterEmployee;
  evaluationHistoryEmployee: IEvaluation;
}
@Module
export default class EmployeeModule extends VuexModule implements StoreInfo {
  employee = {} as IEmployee;
  listEmployeeAttachments = [] as Array<IEmployeeAttachment>;
  listEmployeeComments = [] as Array<IEmployeeComment>;
  listEmployeeEmployments = [] as Array<IEmployeeEmployment>;
  totalEmployeeEmployment = 0;
  listEmployees = [] as Array<IEmployee>;
  listEmployeePositions = [] as Array<IEmployeePosition>;
  listEmployeeEvaluations = [] as Array<IEmployeeEvaluation>;
  totalListEmployeeEvaluations = 0;
  filterListEmployeeEvaluations = {} as IFilterEmployeeEvaluation;
  totalEmployee = 0;
  filterEmployee = {} as IFilterEmployee;
  evaluationHistoryEmployee = {} as IEvaluation;

  /**
   * Get employee
   * @returns IEmployee
   */
  get getEmployee(): IEmployee {
    return this.employee;
  }

  /**
   * Get current total employee
   * @returns IEmployee
   */
  get getListEmployees(): Array<IEmployee> {
    return this.listEmployees;
  }

  /**
   * Get current employee list
   * @returns number
   */
  get getTotalEmployee(): number {
    return this.totalEmployee;
  }

  /**
   * Get current total employee
   * @returns IEmployeeAttachment
   */
  get getListEmployeeAttachments(): Array<IEmployeeAttachment> {
    return this.listEmployeeAttachments;
  }

  /**
   * Get current total employee
   * @returns IEmployeeEmployment
   */
  get getListEmployeeEmployments(): Array<IEmployeeEmployment> {
    return this.listEmployeeEmployments;
  }

  /**
   * Get current employee list
   * @returns number
   */
  get getTotalEmployeeEmployment(): number {
    return this.totalEmployeeEmployment;
  }

  /**
   * Get current total employee
   * @returns IEmployeeComment
   */
  get getListEmployeeComments(): Array<IEmployeeComment> {
    return this.listEmployeeComments;
  }

  /**
   * Get current list employee positions
   * @returns IEmployeePosition
   */
  get getListEmployeePositions(): Array<IEmployeePosition> {
    return this.listEmployeePositions;
  }

  /**
   * Get current list employee evaluations
   * @returns IEmployeeEvaluation
   */
  get getListEmployeeEvaluations(): Array<IEmployeeEvaluation> {
    return this.listEmployeeEvaluations;
  }

  /**
   * Get current total listEmployeeEvaluations
   * @returns number
   */
  get getTotalListEmployeeEvaluations(): number {
    return this.totalListEmployeeEvaluations;
  }

  /**
   * Get current filter list employee evaluation
   * @returns IFilterEmployeeEvaluation
   */
  get getFilterListEmployeeEvaluations(): IFilterEmployeeEvaluation {
    return this.filterListEmployeeEvaluations;
  }

  /**
   * Get current filter employee
   * @returns IFilterEmployee
   */
  get getFilterEmployee(): IFilterEmployee {
    return this.filterEmployee;
  }

  /**
   * Get evaluation history employee
   * @returns IEvaluation
   */
  get getEvaluationHistoryEmployee(): IEvaluation {
    return this.evaluationHistoryEmployee;
  }

  @Mutation
  [Mutations.SET_FILTER_EMPLOYEE](data: IFilterEmployee) {
    this.filterEmployee = data;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE](data: IEmployee) {
    this.employee = data;
  }

  @Mutation
  [Mutations.SET_LIST_EMPLOYEE_ATTACHMENT](data: Array<IEmployeeAttachment>) {
    this.listEmployeeAttachments = data;
  }

  @Mutation
  [Mutations.SET_LIST_EMPLOYEE_EMPLOYMENT](data: Array<IEmployeeEmployment>) {
    this.listEmployeeEmployments = data;
  }

  @Mutation
  [Mutations.SET_TOTAL_EMPLOYEE_EMPLOYMENT](data: number) {
    this.totalEmployeeEmployment = data;
  }

  @Mutation
  [Mutations.SET_LIST_EMPLOYEE](data: Array<IEmployee>) {
    this.listEmployees = data;
  }

  @Mutation
  [Mutations.SET_TOTAL_EMPLOYEE](data: number) {
    this.totalEmployee = data;
  }

  @Mutation
  [Mutations.SET_LIST_EMPLOYEE_COMMENT](data: Array<IEmployeeComment>) {
    this.listEmployeeComments = data;
  }

  @Mutation
  [Mutations.SET_LIST_EMPLOYEE_POSITION](data: Array<IEmployeePosition>) {
    this.listEmployeePositions = data;
  }

  @Mutation
  [Mutations.SET_LIST_EMPLOYEE_EVALUATION](data: Array<IEmployeeEvaluation>) {
    this.listEmployeeEvaluations = data;
  }

  @Mutation
  [Mutations.SET_TOTAL_LIST_EMPLOYEE_EVALUATION](data: number) {
    this.totalListEmployeeEvaluations = data;
  }

  @Mutation
  [Mutations.SET_FILTER_LIST_EMPLOYEE_EVALUATION](
    data: IFilterEmployeeEvaluation
  ) {
    this.filterListEmployeeEvaluations = data;
  }

  @Mutation
  [Mutations.SET_EVALUATION_HISTORY_MODAL](data: IEvaluation) {
    this.evaluationHistoryEmployee = data;
  }

  @Action
  [Actions.DETAIL_EMPLOYEE](id: number) {
    ApiService.setHeader();
    return ApiService.get(`v1/employee/${id}`)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EMPLOYEE} | DETAIL_EMPLOYEE | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_EMPLOYEE, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EMPLOYEE} | DETAIL_EMPLOYEE | error | `,
          response
        );
        this.context.commit(Mutations.SET_EMPLOYEE, {});
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.UPDATE_EMPLOYEE](payload: IParams) {
    ApiService.setHeader();
    return ApiService.patch(`v1/employee/${payload.id}`, payload.payload)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EMPLOYEE} | UPDATE_EMPLOYEE | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EMPLOYEE} | UPDATE_EMPLOYEE | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.LIST_EMPLOYEE_EMPLOYMENT](id: number) {
    ApiService.setHeader();
    return ApiService.get(`v1/employee/${id}/employment`)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EMPLOYEE} | LIST_EMPLOYEE_EMPLOYMENT | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(
          Mutations.SET_LIST_EMPLOYEE_EMPLOYMENT,
          data.content
        );
        this.context.commit(
          Mutations.SET_TOTAL_EMPLOYEE_EMPLOYMENT,
          data.totalElements
        );
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EMPLOYEE} | LIST_EMPLOYEE_EMPLOYMENT | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.LIST_EMPLOYEE_COMMENT](id: number) {
    ApiService.setHeader();
    return ApiService.get(`v1/employee/${id}/comment`)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EMPLOYEE} | LIST_EMPLOYEE_COMMENT | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_LIST_EMPLOYEE_COMMENT, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EMPLOYEE} | LIST_EMPLOYEE_COMMENT | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.CREATE_COMMENT](payload: IParams) {
    ApiService.setHeader();
    return ApiService.post(`v1/employee/${payload.id}/comment`, payload.payload)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EMPLOYEE} | CREATE_COMMENT | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EMPLOYEE} | CREATE_COMMENT | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.LIST_EMPLOYEE_ATTACHMENT](id: number) {
    ApiService.setHeader();
    return ApiService.get(`v1/employee/${id}/attachment`)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EMPLOYEE} | LIST_EMPLOYEE_ATTACHMENT | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_LIST_EMPLOYEE_ATTACHMENT, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EMPLOYEE} | LIST_EMPLOYEE_ATTACHMENT | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.LIST_EMPLOYEE_POSITION](id: number) {
    ApiService.setHeader();
    return ApiService.get(`v1/employee/${id}/position`)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EMPLOYEE} | LIST_EMPLOYEE_POSITION | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_LIST_EMPLOYEE_POSITION, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EMPLOYEE} | LIST_EMPLOYEE_POSITION | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.LIST_EMPLOYEE](params: object) {
    ApiService.setHeader();
    return ApiService.query("/v1/employee", {
      params: params,
    })
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EMPLOYEE} | LIST_EMPLOYEE | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_LIST_EMPLOYEE, data.content);
        this.context.commit(Mutations.SET_TOTAL_EMPLOYEE, data.totalElements);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EMPLOYEE} | LIST_EMPLOYEE | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.LIST_EMPLOYEE_EVALUATION](params: IParams) {
    ApiService.setHeader();
    return ApiService.query(`v1/employee/${params.id}/evaluation`, {
      params: params.payload,
    })
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EMPLOYEE} | LIST_EMPLOYEE_EVALUATION | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(
          Mutations.SET_LIST_EMPLOYEE_EVALUATION,
          data.content
        );
        this.context.commit(
          Mutations.SET_TOTAL_LIST_EMPLOYEE_EVALUATION,
          data.totalElements
        );
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EMPLOYEE} | LIST_EMPLOYEE_EVALUATION | error | `,
          response
        );
        this.context.commit(Mutations.SET_LIST_EMPLOYEE_EVALUATION, []);
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }
}
