import { ICustom, IParameter } from "@/core/interfaces/parameter.interface";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "../enums/StoreEnums";
import { ERROR, TAG_MODULE_PARAMETER } from "@/core/helpers/constants";
import ApiService from "@/core/services/ApiService";
import { IParams } from "@/core/interfaces/user.interfaces";

export interface StoreInfo {
  parameter: IParameter;
}
@Module
export default class ParameterModule extends VuexModule implements StoreInfo {
  parameter = {} as IParameter;

  /**
   * Get parameter
   * @returns IParameter
   */
  get getParameter(): IParameter {
    return this.parameter;
  }

  /**
   * Get list customs
   * @returns ICustom
   */
  get getListCustoms(): Array<ICustom> {
    return this.parameter.customs;
  }

  @Mutation
  [Mutations.SET_PARAMETER](data: IParameter) {
    this.parameter = data;
  }

  @Action
  [Actions.DETAIL_PARAMETER]() {
    ApiService.setHeader();
    return ApiService.get(`v1/parameter`)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_PARAMETER} | DETAIL_PARAMETER | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_PARAMETER, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_PARAMETER} | DETAIL_PARAMETER | error | `,
          response
        );
        this.context.commit(Mutations.SET_PARAMETER, {});
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.CREATE_PARAMETER](payload: object) {
    ApiService.setHeader();
    return ApiService.post("v1/parameter/custom", payload)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_PARAMETER} | CREATE_PARAMETER | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_PARAMETER} | CREATE_PARAMETER | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.UPDATE_PARAMETER](payload: object) {
    ApiService.setHeader();
    return ApiService.patch(`v1/parameter/global`, payload)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_PARAMETER} | UPDATE_PARAMETER | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_PARAMETER} | UPDATE_PARAMETER | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.UPDATE_PARAMETER_DELETE](payload: IParams) {
    ApiService.setHeader();
    return ApiService.patch(
      `v1/parameter/custom/${payload.id}`,
      payload.payload
    )
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_PARAMETER} | UPDATE_PARAMETER_DELETE | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_PARAMETER} | UPDATE_PARAMETER_DELETE | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }
}
