import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { ERROR, TAG_MODULE_EXAM_SCHEDULE } from "@/core/helpers/constants";
import {
  IExamSchedule,
  IFilterExamSchedule,
  IParams,
} from "@/core/interfaces/examSchedule.interface";

export interface StoreInfo {
  listExamSchedule: Array<IExamSchedule>;
  totalExamSchedule: number;
  filterExamSchedule: IFilterExamSchedule;
}

@Module
export default class ExamScheduleModule
  extends VuexModule
  implements StoreInfo
{
  examSchedule = {} as IExamSchedule;
  listExamSchedule = [] as Array<IExamSchedule>;
  totalExamSchedule = 0;
  filterExamSchedule = {} as IFilterExamSchedule;

  /**
   * Get examSchedule
   * @returns IExamSchedule
   */
  get getExamSchedule(): IExamSchedule {
    return this.examSchedule;
  }

  /**
   * Get current list exam schedule
   * @returns Array<IExamSchedule>
   */
  get getListExamSchedule(): Array<IExamSchedule> {
    return this.listExamSchedule;
  }

  /**
   * Get current total exam schedule
   * @returns number
   */
  get getTotalExamSchedule(): number {
    return this.totalExamSchedule;
  }

  /**
   * Get current filter exam schedule
   * @returns IFilterExamSchedule
   */
  get getFilterExamSchedule(): IFilterExamSchedule {
    return this.filterExamSchedule;
  }

  @Mutation
  [Mutations.SET_EXAM_SCHEDULE](data: IExamSchedule) {
    this.examSchedule = data;
  }

  @Mutation
  [Mutations.SET_LIST_EXAM_SCHEDULE](data: Array<IExamSchedule>) {
    this.listExamSchedule = data;
  }

  @Mutation
  [Mutations.SET_TOTAL_EXAM_SCHEDULE](data: number) {
    this.totalExamSchedule = data;
  }

  @Mutation
  [Mutations.SET_FILTER_EXAM_SCHEDULE](data: IFilterExamSchedule) {
    this.filterExamSchedule = data;
  }

  @Action
  [Actions.LIST_EXAM_SCHEDULE](params: object) {
    ApiService.setHeader();
    return ApiService.query("/v1/exam-schedule", {
      params: params,
    })
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EXAM_SCHEDULE} | LIST_EXAM_SCHEDULE | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_LIST_EXAM_SCHEDULE, data.content);
        this.context.commit(
          Mutations.SET_TOTAL_EXAM_SCHEDULE,
          data.totalElements
        );
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EXAM_SCHEDULE} | LIST_EXAM_SCHEDULE | error | `,
          response
        );
        this.context.commit(Mutations.SET_LIST_EXAM_SCHEDULE, []);
        this.context.commit(
          Mutations.SET_TOTAL_EXAM_SCHEDULE,
          response || ERROR
        );
      });
  }

  @Action
  [Actions.CREATE_EXAM_SCHEDULE](payload: object) {
    ApiService.setHeader();
    return ApiService.post("v1/exam-schedule", payload)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EXAM_SCHEDULE} | CREATE_EXAM_SCHEDULE | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_API_RESPONSE, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EXAM_SCHEDULE} | CREATE_EXAM_SCHEDULE | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
        this.context.commit(Mutations.SET_API_RESPONSE, response.data);
      });
  }

  @Action
  [Actions.UPDATE_EXAM_SCHEDULE](payload: IParams) {
    ApiService.setHeader();
    return ApiService.patch(`v1/exam-schedule/${payload.id}`, payload.payload)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EXAM_SCHEDULE} | UPDATE_EXAM_SCHEDULE | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EXAM_SCHEDULE} | UPDATE_EXAM_SCHEDULE | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.DETAIL_EXAM_SCHEDULE](id: number) {
    ApiService.setHeader();
    return ApiService.get(`v1/exam-schedule/${id}`)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EXAM_SCHEDULE} | DETAIL_EXAM_SCHEDULE | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_EXAM_SCHEDULE, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EXAM_SCHEDULE} | DETAIL_EXAM_SCHEDULE | error | `,
          response
        );
        this.context.commit(Mutations.SET_EXAM_SCHEDULE, {});
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.SEND_NOTIFICATION_EXAM_SCHEDULE](id: number) {
    ApiService.setHeader();
    return ApiService.post(`v1/exam-schedule/${id}/notification`, {})
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EXAM_SCHEDULE} | SEND_NOTIFICATION_EXAM_SCHEDULE | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EXAM_SCHEDULE} | SEND_NOTIFICATION_EXAM_SCHEDULE | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }
}
