import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "../enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { ERROR, TAG_MODULE_EVALUATION } from "@/core/helpers/constants";
import {
  IEvaluation,
  IEvaluationResult,
  IFilterEvaluation,
  IQuestion,
} from "@/core/interfaces/evaluation.interface";
import { IParams } from "@/core/interfaces/user.interfaces";

export interface StoreInfo {
  evaluation: IEvaluation;
  evaluationResult: IEvaluationResult;
  listEvaluation: Array<IEvaluation>;
  totalEvaluation: number;
  filterEvaluation: IFilterEvaluation;
  codeEvaluation: string;
  auth: boolean;
  password: string;
}

@Module
export default class EvaluationModule extends VuexModule implements StoreInfo {
  evaluation = {} as IEvaluation;
  evaluationResult = {} as IEvaluationResult;
  listEvaluation = [] as Array<IEvaluation>;
  totalEvaluation = 0;
  filterEvaluation = {} as IFilterEvaluation;
  codeEvaluation = "";
  auth = false;
  password= "";

  /**
   * Get evaluation
   * @returns IEvaluation
   */
  get getEvaluation(): IEvaluation {
    return this.evaluation;
  }

    /**
   * Get evaluation
   * @returns IEvaluation
   */
    get getEvaluationResult(): IEvaluationResult {
      return this.evaluationResult;
    }

  /**
   * Get current list evaluation
   * @returns Array<IEvaluation>
   */
  get getListEvaluation(): Array<IEvaluation> {
    return this.listEvaluation;
  }

  /**
   * Get current total evaluation
   * @returns number
   */
  get getTotalEvaluation(): number {
    return this.totalEvaluation;
  }

  /**
   * Get current filter evaluation
   * @returns IFilterEvaluation
   */
  get getFilterEvaluation(): IFilterEvaluation {
    return this.filterEvaluation;
  }

  /**
   * Get code evaluation
   * @returns string
   */
  get getCodeEvaluation(): string {
    return this.codeEvaluation;
  }

  /**
   * Get password evaluation
   * @returns string
   */
  get getPasswordEvaluation(): string {
    return this.password;
  }

  /**
   * Get evaluation
   * @returns IEvaluation
   */
  get getAuthEvaluation(): boolean {
    return this.auth;
  }

  @Mutation
  [Mutations.SET_EVALUATION](data: IEvaluation) {
    const questions =  data.lastExam?.questions.map((item: IQuestion) => {
      return {
        ...item,
        result: [],
      };
    });
    data.lastExam.questions = questions
    this.evaluation = data;
  }

  @Mutation
  [Mutations.SET_EVALUATION_RESULT](data: IEvaluationResult) {
    this.evaluationResult = data;
  }

  @Mutation
  [Mutations.SET_LIST_EVALUATION](data: Array<IEvaluation>) {
    this.listEvaluation = data;
  }

  @Mutation
  [Mutations.SET_TOTAL_EVALUATION](data: number) {
    this.totalEvaluation = data;
  }

  @Mutation
  [Mutations.SET_FILTER_EVALUATION](data: IFilterEvaluation) {
    this.filterEvaluation = data;
  }

  @Mutation
  [Mutations.SET_AUTH_EVALUATION](data: boolean) {
    this.auth = data;
  }

  @Mutation
  [Mutations.SET_PASSWORD_EVALUATION](data: string) {
    this.password = data;
  }

  @Mutation
  [Mutations.SET_CODE_EVALUATION](data: string) {
    this.codeEvaluation = data;
  }

  @Action
  [Actions.LIST_EVALUATION](params: object) {
    ApiService.setHeader();
    return ApiService.query("/v1/evaluation", {
      params: params,
    })
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EVALUATION} | LIST_EVALUATION | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_LIST_EVALUATION, data.content);
        this.context.commit(Mutations.SET_TOTAL_EVALUATION, data.totalElements);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EVALUATION} | LIST_EVALUATION | error | `,
          response
        );
        this.context.commit(Mutations.SET_LIST_EVALUATION, []);
        this.context.commit(Mutations.SET_TOTAL_EVALUATION, response || ERROR);
      });
  }

  @Action
  [Actions.DETAIL_EVALUATION](id: number) {
    return ApiService.get(`v1/evaluation/${id}`)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EVALUATION} | DETAIL_EVALUATION | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_EVALUATION, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EVALUATION} | DETAIL_EVALUATION | error | `,
          response
        );
        this.context.commit(Mutations.SET_EVALUATION, {});
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.DETAIL_PUBLIC_EVALUATION_RESULT](id: number) {
    return ApiService.get(`v1/evaluation/public/result/${id}`)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EVALUATION} | DETAIL_PUBLIC_EVALUATION_RESULT | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_EVALUATION_RESULT, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EVALUATION} | DETAIL_PUBLIC_EVALUATION_RESULT | error | `,
          response
        );
        this.context.commit(Mutations.SET_EVALUATION_RESULT, {});
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.DETAIL_PUBLIC_EVALUATION](id: number) {
    return ApiService.get(`v1/evaluation/public/${id}`)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EVALUATION} | DETAIL_PUBLIC_EVALUATION | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_EVALUATION, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EVALUATION} | DETAIL_PUBLIC_EVALUATION | error | `,
          response
        );
        this.context.commit(Mutations.SET_EVALUATION, {});
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.AUTH_EVALUATION](payload: object) {
    return ApiService.post(`v1/evaluation/public/auth`, payload)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EVALUATION} | DETAIL_EVALUATION | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_AUTH_EVALUATION, data?.auth);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EVALUATION} | DETAIL_EVALUATION | error | `,
          response
        );
        this.context.commit(Mutations.SET_AUTH_EVALUATION, false);
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.RESULT_EVALUATION](payload: IParams) {
    return ApiService.post(`v1/evaluation/public/${payload.id}/result`, payload.payload)
      .then(({ data }) => {
        console.log(`${TAG_MODULE_EVALUATION} | CREATE_EVALUATION | success | `, data);
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_API_RESPONSE, data);
      })
      .catch(({ response }) => {
        console.log(`${TAG_MODULE_EVALUATION} | CREATE_EVALUATION | error | `, response);
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
        this.context.commit(Mutations.SET_API_RESPONSE, response.data);
      });
  }
}
