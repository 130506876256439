import { Module, VuexModule, Action } from "vuex-module-decorators";
import { Actions } from "@/store/enums/StoreEnums";

export interface StoreInfo {
  classes: {
    header?: Array<string>;
    headerContainer?: Array<string>;
    headerMobile?: Array<string>;
    headerMenu?: Array<string>;
    aside?: Array<string>;
    asideMenu?: Array<string>;
    asideToggle?: Array<string>;
    toolbar?: Array<string>;
    toolbarContainer?: Array<string>;
    content?: Array<string>;
    contentContainer?: Array<string>;
    footerContainer?: Array<string>;
    sidebar?: Array<string>;
    pageTitle?: Array<string>;
  };
}

/* eslint-disable */
@Module
export default class BodyModule extends VuexModule implements StoreInfo {
  classes = {};

  @Action
  [Actions.ADD_BODY_CLASSNAME](className: string) {
    // console.log("ADD_BODY_CLASSNAME", className);
    document.body.classList.add(className);
  }

  @Action
  [Actions.REMOVE_BODY_CLASSNAME](className: string) {
    // console.log("REMOVE_BODY_CLASSNAME", className);
    document.body.classList.remove(className);
  }

  @Action
  [Actions.ADD_BODY_ATTRIBUTE](payload: any) {
    const { qulifiedName, value } = payload;
    document.body.setAttribute(qulifiedName, value);
  }
}
