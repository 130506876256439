import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { ERROR, TAG_MODULE_EMPLOYMENT } from "@/core/helpers/constants";

interface IParamsEmployment {
  employeeId: number;
  employmentId: number;
  payload: object;
}
export interface ApiResponse {
  code: string;
  message?: string;
  status: number;
}
export interface StoreInfo {
  apiResponse: ApiResponse;
}
@Module
export default class EmploymentModule extends VuexModule implements StoreInfo {
  apiResponse = {} as ApiResponse;
  listEmploymentCategory = [] as Array<string>;
  listEmploymentPosition = [] as Array<string>;

  /**
   * Get current apiResponse object
   * @returns ApiResponse
   */
  get getApiResponse(): ApiResponse {
    return this.apiResponse;
  }

  /**
   * Get current string list
   * @returns string
   */
  get getListEmploymentCategory(): Array<string> {
    return this.listEmploymentCategory;
  }

  /**
   * Get current string list
   * @returns string
   */
  get getListEmploymentPosition(): Array<string> {
    return this.listEmploymentPosition;
  }

  @Mutation
  [Mutations.SET_API_RESPONSE](data: ApiResponse) {
    this.apiResponse = data;
  }

  @Mutation
  [Mutations.SET_LIST_EMPLOYMENT_CATEGORY](data: Array<string>) {
    this.listEmploymentCategory = data;
  }

  @Mutation
  [Mutations.SET_LIST_EMPLOYMENT_POSITION](data: Array<string>) {
    this.listEmploymentPosition = data;
  }

  @Action
  [Actions.UPDATE_EMPLOYMENT](payload: IParamsEmployment) {
    ApiService.setHeader();
    return ApiService.patch(
      `v1/employee/${payload.employeeId}/employment/${payload.employmentId}`,
      payload.payload
    )
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EMPLOYMENT} | UPDATE_EMPLOYMENT | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EMPLOYMENT} | UPDATE_EMPLOYMENT | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.CREATE_EMPLOYMENT](payload: object) {
    ApiService.setHeader();
    return ApiService.post("v1/employment", payload)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EMPLOYMENT} | CREATE_EMPLOYMENT | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_API_RESPONSE, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EMPLOYMENT} | CREATE_EMPLOYMENT | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
        this.context.commit(Mutations.SET_API_RESPONSE, response.data);
      });
  }

  @Action
  [Actions.UPLOAD_CREATE_EMPLOYMENT](payload: object) {
    return ApiService.post("/v1/employment/create/upload", payload)
      .then(({ data }) => {
        this.context.commit(Mutations.PURGE_ERROR);
        console.log(
          `${TAG_MODULE_EMPLOYMENT} | UPLOAD_CREATE_EMPLOYMENT | success | `,
          data
        );
        return data;
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EMPLOYMENT} | UPLOAD_CREATE_EMPLOYMENT | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
        return response;
      });
  }

  @Action
  [Actions.UPLOAD_UPDATE_EMPLOYMENT](payload: object) {
    return ApiService.post("/v1/employment/update/upload", payload)
      .then(({ data }) => {
        this.context.commit(Mutations.PURGE_ERROR);
        console.log(
          `${TAG_MODULE_EMPLOYMENT} | UPLOAD_UPDATE_EMPLOYMENT | success | `,
          data
        );
        return data;
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EMPLOYMENT} | UPLOAD_UPDATE_EMPLOYMENT | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
        return response;
      });
  }

  @Action
  [Actions.LIST_EMPLOYMENT_CATEGORY]() {
    ApiService.setHeader();
    return ApiService.get("/v1/employment/category")
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EMPLOYMENT} | LIST_EMPLOYMENT_CATEGORY | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_LIST_EMPLOYMENT_CATEGORY, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EMPLOYMENT} | LIST_EMPLOYMENT_CATEGORY | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.LIST_EMPLOYMENT_POSITION]() {
    ApiService.setHeader();
    return ApiService.get("/v1/employment/position")
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_EMPLOYMENT} | LIST_EMPLOYMENT_POSITION | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_LIST_EMPLOYMENT_POSITION, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_EMPLOYMENT} | LIST_EMPLOYMENT_POSITION | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }
}
