import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import {
  IFilterUser,
  IParams,
  IRole,
  IUser,
} from "@/core/interfaces/user.interfaces";
import { ERROR, TAG_MODULE_USER } from "@/core/helpers/constants";

export interface StoreInfo {
  user: IUser;
  listUsers: Array<IUser>;
  listRoles: Array<IRole>;
  totalUser: number;
  filterUser: IFilterUser;
}

@Module
export default class UserModule extends VuexModule implements StoreInfo {
  user = {} as IUser;
  listUsers = [] as Array<IUser>;
  listRoles = [] as Array<IRole>;
  totalUser = 0;
  filterUser = {} as IFilterUser;

  /**
   * Get employee
   * @returns IUser
   */
  get getUser(): IUser {
    return this.user;
  }

  /**
   * Get current total users
   * @returns IUser
   */
  get getListUsers(): Array<IUser> {
    return this.listUsers;
  }

  /**
   * Get current user list
   * @returns number
   */
  get getTotalUser(): number {
    return this.totalUser;
  }

  /**
   * Get current total roles
   * @returns IRole
   */
  get getListRoles(): Array<IRole> {
    return this.listRoles;
  }

  /**
   * Get current filter user
   * @returns IFilterUser
   */
  get getFilterUser(): IFilterUser {
    return this.filterUser;
  }

  @Mutation
  [Mutations.SET_FILTER_USER](data: IFilterUser) {
    this.filterUser = data;
  }

  @Mutation
  [Mutations.SET_USER](data: IUser) {
    this.user = data;
  }

  @Mutation
  [Mutations.SET_LIST_USER](data: Array<IUser>) {
    this.listUsers = data;
  }

  @Mutation
  [Mutations.SET_LIST_ROLE](data: Array<IRole>) {
    this.listRoles = data;
  }

  @Mutation
  [Mutations.SET_TOTAL_USER](data: number) {
    this.totalUser = data;
  }

  @Action
  [Actions.LIST_USER](params: object) {
    ApiService.setHeader();
    return ApiService.query("/v1/user", {
      params: params,
    })
      .then(({ data }) => {
        console.log(`${TAG_MODULE_USER} | LIST_USER | success | `, data);
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_LIST_USER, data.content);
        this.context.commit(Mutations.SET_TOTAL_USER, data.totalElements);
      })
      .catch(({ response }) => {
        console.log(`${TAG_MODULE_USER} | LIST_USER | error | `, response);
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.LIST_ROLE]() {
    ApiService.setHeader();
    return ApiService.get("/v1/role")
      .then(({ data }) => {
        console.log(`${TAG_MODULE_USER} | LIST_ROLE | success | `, data);
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_LIST_ROLE, data.content);
      })
      .catch(({ response }) => {
        console.log(`${TAG_MODULE_USER} | LIST_ROLE | error | `, response);
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.CREATE_USER](payload: object) {
    ApiService.setHeader();
    return ApiService.post("v1/user", payload)
      .then(({ data }) => {
        console.log(`${TAG_MODULE_USER} | CREATE_USER | success | `, data);
        this.context.commit(Mutations.PURGE_ERROR);
      })
      .catch(({ response }) => {
        console.log(`${TAG_MODULE_USER} | CREATE_USER | error | `, response);
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.DETAIL_USER](id: number) {
    ApiService.setHeader();
    return ApiService.get(`v1/user/${id}`)
      .then(({ data }) => {
        console.log(`${TAG_MODULE_USER} | DETAIL_USER | success | `, data);
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_USER, data);
      })
      .catch(({ response }) => {
        console.log(`${TAG_MODULE_USER} | DETAIL_USER | error | `, response);
        this.context.commit(Mutations.SET_USER, {});
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.UPDATE_USER](payload: IParams) {
    ApiService.setHeader();
    return ApiService.patch(`v1/user/${payload.id}`, payload.payload)
      .then(({ data }) => {
        console.log(`${TAG_MODULE_USER} | UPDATE_USER | success | `, data);
        this.context.commit(Mutations.PURGE_ERROR);
      })
      .catch(({ response }) => {
        console.log(`${TAG_MODULE_USER} | UPDATE_USER | error | `, response);
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }
}
