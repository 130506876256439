import { createStore } from "vuex";
import { config } from "vuex-module-decorators"; // hel with decorator in the store

import ConfigModule from "@/store/modules/ConfigModule";
import AuthModule from "@/store/modules/AuthModule";
import BodyModule from '@/store/modules/BodyModule';
import EmployeeModule from './modules/EmployeeModule';
import PartnerModule from './modules/PartnerModule';
import UserModule from "./modules/UserModule";
import EmploymentModule from './modules/EmploymentModule';
import ParameterModule from './modules/ParameterModule';
import BreadcrumbsModule from './modules/BreadcrumbsModule';
import ContactListModule from './modules/ContactListModule';
import ExamModule from "./modules/ExamModule";
import ExamScheduleModule from "./modules/ExamScheduleModule";
import EvaluationModule from './modules/EvaluationModule';

config.rawError = true;
// before here createStore({state, getters, mutations, actions, module})
const store = createStore({
  modules: {
    AuthModule,
    EmployeeModule,
    PartnerModule,
    UserModule,
    EvaluationModule,
    ContactListModule,
    ExamModule,
    ExamScheduleModule,
    BodyModule,
    EmploymentModule,
    ParameterModule,
    BreadcrumbsModule,
    // BreadcrumbsModule,
    ConfigModule,
    // ThemeModeModule,
  },
});

export default store;
