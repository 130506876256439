/* TAG LOGS - MODULE */
export const TAG_MODULE = "module | ";
export const TAG_MODULE_AUTH = `${TAG_MODULE} auth | `;
export const TAG_MODULE_EMPLOYEE = `${TAG_MODULE} employee | `;
export const TAG_MODULE_EMPLOYMENT = `${TAG_MODULE} employment | `;
export const TAG_MODULE_PARAMETER = `${TAG_MODULE} parameter | `;
export const TAG_MODULE_USER = `${TAG_MODULE} user | `;
export const TAG_MODULE_PARTNER = `${TAG_MODULE} partner | `;
export const TAG_MODULE_CONTACT_LISTS = `${TAG_MODULE} contact-lists | `;
export const TAG_MODULE_EXAM = `${TAG_MODULE} exan | `;
export const TAG_MODULE_EVALUATION = `${TAG_MODULE} evaluation | `;
export const TAG_MODULE_EXAM_SCHEDULE = `${TAG_MODULE} exan-schedule | `;

/* TAG HTTP STATUS */
export const HTTP_STATUS_000 = 0;
export const HTTP_STATUS_200 = 200;
export const HTTP_STATUS_201 = 201;
export const HTTP_STATUS_202 = 202;
export const HTTP_STATUS_400 = 400;
export const HTTP_STATUS_409 = 409;
export const HTTP_STATUS_500 = 500;

/* TAG CODE ERROR */
export const CODE_ERROR_100 = "100";
export const CODE_ERROR_210 = "210";
export const CODE_ERROR_220 = "220";
export const CODE_ERROR_230 = "230";
export const CODE_ERROR_240 = "240";
export const CODE_ERROR_250 = "250";

/* TABLE CONFIG */
export const TABLE_ROW_INIT = 10;
export const TABLE_ROW_OPTIONS = [TABLE_ROW_INIT, 20, 50];
export const LIST_TOTAL_PARTNERS = {
    size: 999999999,
    page: 0,
}

// CONSTANTS GENERAL
export const ERROR = "API - ERROR";
export const INIT_URL = "employment-register";

