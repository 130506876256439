import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { ERROR, TAG_MODULE_CONTACT_LISTS } from "@/core/helpers/constants";
import {
  IContactCustom,
  IContactList,
  IContactListPreview,
  IFilterContactList,
  IParams,
} from "@/core/interfaces/contact.interface";

export interface StoreInfo {
  contactList: IContactList;
  listContactLists: Array<IContactList>;
  totalContactLists: number;
  filterContactLists: IFilterContactList;
  listContactCustom: Array<IContactCustom>;
}

@Module
export default class ContactListModule extends VuexModule implements StoreInfo {
  contactList = {} as IContactList;
  contactListPreview = [] as Array<IContactListPreview>;
  listContactLists = [] as Array<IContactList>;
  totalContactLists = 0;
  totalContactListPreview = 0;
  filterContactLists = {} as IFilterContactList;
  listContactCustom = [] as Array<IContactCustom>;

  /**
   * Get contactLists
   * @returns IContactList
   */
  get getContactList(): IContactList {
    return this.contactList;
  }

  /**
   * Get contactListPreview
   * @returns IContactListPreview
   */
  get getContactListPreview(): Array<IContactListPreview> {
    return this.contactListPreview;
  }

  /**
   * Get current list contactLists
   * @returns IContactList
   */
  get getListContactLists(): Array<IContactList> {
    return this.listContactLists;
  }

  /**
   * Get current total contactLists
   * @returns number
   */
  get getTotalContactLists(): number {
    return this.totalContactLists;
  }

  /**
   * Get current total contactListPreview
   * @returns number
   */
  get getTotalContactListPreview(): number {
    return this.totalContactListPreview;
  }

  /**
   * Get current filter contactLists
   * @returns IFilterContactList
   */
  get getFilterContactLists(): IFilterContactList {
    return this.filterContactLists;
  }

  /**
   * Get current string list
   * @returns string
   */
  get getListContactCustom(): Array<IContactCustom> {
    return this.listContactCustom;
  }

  @Mutation
  [Mutations.SET_FILTER_CONTACT_LISTS](data: IFilterContactList) {
    this.filterContactLists = data;
  }

  @Mutation
  [Mutations.SET_CONTACT_LIST](data: IContactList) {
    this.contactList = data;
  }

  @Mutation
  [Mutations.SET_CONTACT_LIST_PREVIEW](data: Array<IContactListPreview>) {
    this.contactListPreview = data;
  }

  @Mutation
  [Mutations.SET_LIST_CONTACT_LISTS](data: Array<IContactList>) {
    this.listContactLists = data;
  }

  @Mutation
  [Mutations.SET_TOTAL_CONTACT_LISTS](data: number) {
    this.totalContactLists = data;
  }

  @Mutation
  [Mutations.SET_TOTAL_CONTACT_LIST_PREVIEW](data: number) {
    this.totalContactListPreview = data;
  }

  @Mutation
  [Mutations.SET_LIST_CONTACT_CUSTOM](data: Array<IContactCustom>) {
    this.listContactCustom = data;
  }

  @Action
  [Actions.LIST_CONTACT_LISTS](params: object) {
    ApiService.setHeader();
    return ApiService.query("/v1/contact-list", {
      params: params,
    })
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_CONTACT_LISTS} | LIST_CONTACT_LISTS | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_LIST_CONTACT_LISTS, data.content);
        this.context.commit(
          Mutations.SET_TOTAL_CONTACT_LISTS,
          data.totalElements
        );
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_CONTACT_LISTS} | LIST_CONTACT_LISTS | error | `,
          response
        );
        this.context.commit(Mutations.SET_LIST_CONTACT_LISTS, []);
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.LIST_CONTACT_LISTS_RETURN](params: object) {
    ApiService.setHeader();
    return ApiService.query("/v1/contact-list", {
      params: params,
    })
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_CONTACT_LISTS} | LIST_CONTACT_LISTS_RETURN | success | `,
          data
        );
        return data.content;
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_CONTACT_LISTS} | LIST_CONTACT_LISTS_RETURN | error | `,
          response
        );
        return response.data;
      });
  }

  @Action
  [Actions.LIST_CONTACT_PREVIEW](params: IParams) {
    ApiService.setHeader();
    return ApiService.query(`v1/contact-list/preview/${params.id}`, {
      params: params.payload,
    })
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_CONTACT_LISTS} | LIST_CONTACT_PREVIEW | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_CONTACT_LIST_PREVIEW, data.content);
        this.context.commit(
          Mutations.SET_TOTAL_CONTACT_LIST_PREVIEW,
          data.totalElements
        );
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_CONTACT_LISTS} | LIST_CONTACT_PREVIEW | error | `,
          response
        );
        this.context.commit(Mutations.SET_CONTACT_LIST_PREVIEW, []);
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.DETAIL_CONTACT_LIST](id: number) {
    ApiService.setHeader();
    return ApiService.get(`v1/contact-list/${id}`)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_CONTACT_LISTS} | DETAIL_CONTACT_LIST | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_CONTACT_LIST, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_CONTACT_LISTS} | DETAIL_CONTACT_LIST | error | `,
          response
        );
        this.context.commit(Mutations.SET_CONTACT_LIST, {});
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.LIST_CONTACT_CUSTOM](id: number) {
    ApiService.setHeader();
    return ApiService.get(`/v1/contact-custom/contact-list/${id}`)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_CONTACT_LISTS} | LIST_CONTACT_CUSTOM | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_LIST_CONTACT_CUSTOM, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_CONTACT_LISTS} | LIST_CONTACT_CUSTOM | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.CREATE_CONTACT_LISTS](payload: object) {
    ApiService.setHeader();
    return ApiService.post("v1/contact-list", payload)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_CONTACT_LISTS} | CREATE_CONTACT_LISTS | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_API_RESPONSE, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_CONTACT_LISTS} | CREATE_CONTACT_LISTS | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
        this.context.commit(Mutations.SET_API_RESPONSE, response.data);
      });
  }

  @Action
  [Actions.CREATE_LIST_CONTACT_CUSTOM](params: IParams) {
    ApiService.setHeader();
    return ApiService.post(
      `v1/contact-custom/contact-list/${params.id}`,
      params.payload
    )
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_CONTACT_LISTS} | CREATE_LIST_CONTACT_CUSTOM | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_API_RESPONSE, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_CONTACT_LISTS} | CREATE_LIST_CONTACT_CUSTOM | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
        this.context.commit(Mutations.SET_API_RESPONSE, response.data);
      });
  }

  @Action
  [Actions.UPDATE_CONTACT_LISTS](payload: IParams) {
    ApiService.setHeader();
    return ApiService.patch(`v1/contact-list/${payload.id}`, payload.payload)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_CONTACT_LISTS} | UPDATE_CONTACT_LISTS | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_CONTACT_LISTS} | UPDATE_CONTACT_LISTS | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.DELETE_CONTACT_CUSTOM](id: number) {
    ApiService.setHeader();
    return ApiService.delete(`v1/contact-custom/${id}`)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_CONTACT_LISTS} | DELETE_CONTACT_CUSTOM | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_CONTACT_LISTS} | DELETE_CONTACT_CUSTOM | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }
}
