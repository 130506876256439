
import KTLoader from "@/components/Loader.vue";
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { loaderEnabled, loaderLogo } from "@/core/helpers/config";

export default defineComponent({
  name: "app",
  components: {
    KTLoader,
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      nextTick(() => initializeComponents());
    });

    return {
      loaderEnabled,
      loaderLogo,
    }
  },
});
